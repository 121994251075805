import * as React from 'react'
import { SortByFields } from '../../helpers/AlgoliaClient'
import PropTypes from 'prop-types'

const SortByDropDown = ({ facetFilters, handleFacet }) => {
  return (
    <div className="h-8 bg-white flex flex-row justify-center pr-2 ">
      <select
        className="font-bold "
        value={SortByFields.findIndex(
          i => (facetFilters['sort_by'] || []).indexOf('sort_by:' + i.name) >= 0
          || (facetFilters['sort_by'] || []).indexOf('sort_by:' + i.value) >= 0
        )}
        onChange={e => handleFacet('sort_by', SortByFields[e.target.value])}
      >
        {SortByFields.map((option, index) => {
          return (
            <option key={option.value} value={index}>
              {option.name}
            </option>
          )
        })}
      </select>
    </div>
  )
}
SortByDropDown.propTypes = {
  facetFilters: PropTypes.object,
  handleFacet: PropTypes.func,
}

export default SortByDropDown
