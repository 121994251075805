import * as React from 'react'
import FilterFindYourWatch, { SortByComponent } from './FilterFindYourWatch'
import { Helmet } from 'react-helmet'
import { SortByFields } from '../../helpers/AlgoliaClient'
import PropTypes from 'prop-types'

const SortAndFilterButtonAndOptions = ({
  hasResults,
  clearFacetFilters,
  totalCount,
  sortPanelOn,
  filterPanelOn,
  setFilterPanelOn,
  facets,
  facetFilters,
  handleFacet,
  setSortPanelOn,
}) => {
  /*
  const filers = {
    sort_by: ['sort_by:Price (low to high)'],
    price_filter: ['price_filter:$2,500 - $4,999', 'lll'],
    size: ['size:Medium (31mm-39mm)'],
  }
  ["sort_by",..]
  ["price_filter",..]
  //[2, 1]
  let a = 0
  for(let b in filers){
    a = a + filters[b].length
  }
  */
  const noOfFiltersApplied = facetFilters
    ? Object.keys(facetFilters)
        .filter(key => key !== 'sort_by')
        //.map(f => (facetFilters[f] || []).length)
        .reduce((a, b) => a + (facetFilters[b] || []).length, 0)
    : 0
  const [, sortByValue] = facetFilters['sort_by']?.[0]?.split(':') || []
  const sortByOption = SortByFields.find(field => field.name === sortByValue || field.value === sortByValue) || SortByFields[0]
  return (
    <>
      {hasResults && totalCount && (
        <p className="text-sm md:hidden text-right w-11/12 mx-auto">
          <b className="">{totalCount}</b>&ensp;RESULTS
        </p>
      )}
      {hasResults && (
        <div
          className={
            'z-[105] bg-white mb-3 text-gray-600 md:hidden w-full h-12 sticky top-0  lg:z-0 flex flex-row border-t border-b border-gray-300 p-2 shadow-lg'
          }
        >
          <button
            onClick={() => {
              setFilterPanelOn(true)
              setSortPanelOn(false)
            }}
            className="w-full my-1 border-r border-gray-300"
          >
            FILTER
            {noOfFiltersApplied > 0 && (
              <div className="min-w-6 h-6 max-w-max text-xs lg:text-base rounded-full border-2 border-red-600 text-red-600 inline-block ml-2 leading-5 font-bold -translate-y-[.15rem]">
                {Math.min(noOfFiltersApplied, 99)}
              </div>
            )}
          </button>

          <button
            onClick={() => {
              setFilterPanelOn(true)
              setSortPanelOn(true)
            }}
            className="w-full my-1 border-l border-gray-100 flex flex-row items-center justify-center"
          >
            SORT
            <div className="h-5 w-5 text-red-600 ml-2 ">
              {sortByOption?.icon || sortByOption?.name || sortByValue}
            </div>
          </button>
        </div>
      )}
      {filterPanelOn ? (
        <Helmet bodyAttributes={{ class: 'overflow-hidden lg:overflow-y-auto' }} />
      ) : null}
      <div
        className={
          (filterPanelOn //
            ? ' left-[0rem] w-full bg-white px-10 xl:w-1/5 md:w-1/3 md:px-0 pb-20  '
            : ' -left-[120rem] w-0 ') +
          //   ' w-full left-[0vw] opacity-100'
          // : ' -left-[110vw] md:left-[0vw] w-0 opacity-0') +
          '  h-full fixed md:sticky z-[155] px-5 md:px-0 top-0 duration-500 ease-in-out overflow-y-auto overflow-x-clip lg:z-0 '
        }
      >
        <div className="h-12 bg-white border-b w-full sticky top-0 z-[155] lg:z-0 flex items-center text-xl text-red-700 justify-center md:hidden">
          <button
            onClick={() => {
              setFilterPanelOn(false)
              setSortPanelOn(false)
            }}
            className={
              ' absolute top-2 right-0 border border-gray-300 size-8 text-gray-300 z-30 duration-300 ease-in-out'
            }
          >
            &#10005;
          </button>
          {filterPanelOn && !sortPanelOn ? `${totalCount} RESULTS` : 'SORT BY'}
        </div>
        {/* <FilterPanel facets={facets} facetFilters={facetFilters} handleFacet={handleFacet} /> */}

        {sortPanelOn ? (
          <SortByComponent facets={facets} facetFilters={facetFilters} handleFacet={handleFacet} />
        ) : (
          <FilterFindYourWatch
            facets={facets}
            facetFilters={facetFilters}
            handleFacet={handleFacet}
            collapsed={true}
          />
        )}
        {filterPanelOn && !sortPanelOn && (
          <div className="w-full bg-white md:hidden z-[52] fixed bottom-0 flex flex-row justify-center gap-10 px-5 left-0">
            <button
              onClick={() => {
                clearFacetFilters()
                setFilterPanelOn(false)
              }}
              className="border border-gray-600 w-full flex-shrink py-2 my-3"
            >
              CLEAR ALL
            </button>
            <button
              onClick={() => {
                setFilterPanelOn(false)
                setSortPanelOn(false)
              }}
              className={
                ' duration-300 w-full flex-shrink py-2 my-3 text-white ' +
                (facetFilters && Object.keys(facetFilters).length > 0
                  ? 'bg-red-700'
                  : 'bg-gray-400')
              }
            >
              APPLY
            </button>
          </div>
        )}
      </div>
    </>
  )
}
SortAndFilterButtonAndOptions.propTypes = {
  hasResults: PropTypes.bool,
  clearFacetFilters: PropTypes.func,
  totalCount: PropTypes.number,
  sortPanelOn: PropTypes.bool,
  filterPanelOn: PropTypes.bool,
  setFilterPanelOn: PropTypes.func,
  facets: PropTypes.object,
  facetFilters: PropTypes.object,
  handleFacet: PropTypes.func,
  setSortPanelOn: PropTypes.func,
}

export default SortAndFilterButtonAndOptions
